<template>
  <div class="d-flex flex-column flex-grow-1 rounded p-3">
    <BaseToolBar title="Véhicules" subtitle="Liste des véhicules enregistrés">
      <div class="btn-group mr-1" role="group" style="z-index: 2020">
        <download-excel :data="dataExport" class="btn btn-primary cursor-pointer" name="vehicules.xls">
          Exporter (.xls)
          <span class="badge badge-warning">{{ dataExport.length }}</span>
        </download-excel>
        <div class="btn-group" role="group">
          <button
            id="btnGroupDrop1"
            type="button"
            class="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="btnGroupDrop1"
            style="width: 330px"
            v-background-3
          >
            <download-excel :data="dataExport2" class="dropdown-item cursor-pointer" name="vehicules.xls">
              Une ligne par collaborateur (.xls)
              <span class="badge badge-warning">{{ dataExport2.length }}</span>
            </download-excel>
          </div>
        </div>
      </div>
      <BaseButton
        class="btn btn-primary"
        v-tooltip="'Ajouter une formation'"
        @click="storeVehicule"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="mb-4">
      <div class="mb-3">
        <div v-for="vehicule in vehiculeNew" :key="vehicule.id" class="d-flex align-items-center">
          <item :vehicule="vehicule" :data="data" @destroy="onDestroy" @update="onUpdate" />
        </div>
      </div>
      <div>
        <div v-for="vehicule in vehiculeOld" :key="vehicule.id" class="d-flex align-items-center">
          <item :vehicule="vehicule" :data="data" @destroy="onDestroy" @update="onUpdate" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapFields } from "vuex-map-fields";
// import { mapMultiRowFields } from "vuex-map-fields";
// import { mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import BaseToolBar from "@/components/bases/ToolBar.vue";
// import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import BaseButton from "@/components/bases/Button.vue";
import item from "@/components/vehicules/item.vue";
import { lastContratForCollab } from "@/helpers.js";

export default {
  components: {
    BaseToolBar,
    item,
    // ButtonCircle,
    BaseButton,
  },
  data() {
    return {
      data: {},
      vehicules: [],
      loading: false,
    };
  },
  methods: {
    storeVehicule() {
      this.loading = true;
      this.$http
        .post("/vehicules")
        .then((resp) => this.vehicules.push(resp.data))
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    onUpdate(vehicule) {
      const index = this.vehicules.findIndex((x) => x.id === vehicule.id);
      this.vehicules[index].collaborateurs = vehicule.collaborateurs;
      this.vehicules[index].etablissements = vehicule.etablissements;
    },
    onDestroy(id) {
      const index = this.vehicules.findIndex((x) => x.id === id);
      this.vehicules.splice(index, 1);
    },
    descriptionById(list, id) {
      if (!this.data || !this.data[list]) return "";
      const index = this.data[list].findIndex((x) => x.id === id);
      if (this.data[list][index]) return this.data[list][index].description;
      else return "";
    },
    lastCollaborateur(vehicule) {
      const affectations = vehicule.collaborateurs.map((collab) => {
        const lastContrat = lastContratForCollab(collab.collaborateur);
        if (collab && collab.collaborateur && collab.collaborateur.collaborateur_nom)
          return {
            nom: collab.collaborateur.collaborateur_nom,
            prenom: collab.collaborateur.collaborateur_prenom,
            societe: lastContrat.activeVersion.contrat_siret ? lastContrat.activeVersion.contrat_siret.societe : "",
            dt: parseFloat(this.$moment(collab.date_affectation).format("X")),
            dtFr: this.$moment(collab.date_affectation).format("DD/MM/YYYY"),
            restitution: this.$moment(collab.date_restitution).format("DD/MM/YYYY"),
            type: this.descriptionById("categoriesAffectation", collab.categorie_id),
          };
      });
      let max = 0,
        reponse = {};
      if (affectations)
        affectations.forEach((affectation) => {
          if (affectation && affectation.dt && affectation.dt > 0 && max < affectation.dt) reponse = affectation;
        });
      return reponse;
    },
  },
  mounted() {
    this.$http.get("/vehicules").then((resp) => {
      this.vehicules = resp.data;
    });
    this.$http.get("/vehicules-data").then((resp) => {
      this.data = resp.data;
    });
  },
  computed: {
    vehiculeNew() {
      return this.vehicules.filter((vehicule) => vehicule.new == true);
    },

    vehiculeOld() {
      return this.vehicules.filter((vehicule) => vehicule.new == false);
    },

    dataExport() {
      let reponse = [];
      if (!this.vehicules || !this.data) return null;
      this.vehicules.forEach((vehicule) => {
        console.log(this.lastCollaborateur(vehicule));
        if (vehicule && vehicule.modele)
          reponse.push({
            ["véhicule"]: vehicule.modele,
            ["Immatriculation"]: vehicule.immatriculation,
            ["valeur_vehicule"]: vehicule.valeur_vehicule,
            ["valeur_carbone"]: vehicule.valeur_carbone,
            ["N°contrat"]: vehicule.num_contrat,
            ["prestataire_id"]: this.descriptionById("prestataires", vehicule.prestataire_id),
            ["assurance_id"]: this.descriptionById("assurances", vehicule.assurance_id),
            ["categorie_id"]: this.descriptionById("categories", vehicule.categorie_id),
            ["classeur"]: vehicule.modele,
            ["commentaire"]: vehicule.modele,
            ["date_immatriculation"]: vehicule.modele,
            ["date_restitution"]: vehicule.modele,
            ["debut_contrat"]: vehicule.modele,
            ["fin_contrat"]: vehicule.modele,
            ["loyer_ht"]: vehicule.modele,
            ["loyer_ttc"]: vehicule.modele,
            ["modele"]: vehicule.modele,
            ["nb_km"]: vehicule.modele,
            ["puissance_dyn"]: vehicule.modele,
            ["puissance_fiscale"]: vehicule.modele,
            ["societe_id"]: this.descriptionById("societes", vehicule.societe_id),
            ["societe_utilisatrice_id"]: this.descriptionById("societes", vehicule.societe_utilisatrice_id),
            ["tarif_assurance"]: vehicule.modele,
            ["Collaborateur"]: this.lastCollaborateur(vehicule).nom + " " + this.lastCollaborateur(vehicule).prenom,
            ["Société du collaborateur"]: this.lastCollaborateur(vehicule).societe,
            ["Affecté le"]: this.lastCollaborateur(vehicule).dtFr,
            ["Restitution le"]: this.lastCollaborateur(vehicule).restitution,
            ["type affectation"]: this.lastCollaborateur(vehicule).type,
          });
      });
      return reponse;
    },

    dataExport2() {
      let reponse = [];
      if (!this.vehicules || !this.data) return null;
      this.vehicules.forEach((vehicule) => {
        vehicule.collaborateurs.forEach((collab) => {
          if (collab.collaborateur && collab.collaborateur.collaborateur_nom)
            reponse.push({
              ["véhicule"]: vehicule.modele,
              ["Immatriculation"]: vehicule.immatriculation,
              ["N°contrat"]: vehicule.num_contrat,
              ["prestataire_id"]: this.descriptionById("prestataires", vehicule.prestataire_id),
              ["assurance_id"]: this.descriptionById("assurances", vehicule.assurance_id),
              ["categorie_id"]: this.descriptionById("categories", vehicule.categorie_id),
              ["classeur"]: vehicule.modele,
              ["commentaire"]: vehicule.modele,
              ["date_immatriculation"]: vehicule.modele,
              ["date_restitution"]: vehicule.modele,
              ["debut_contrat"]: vehicule.modele,
              ["fin_contrat"]: vehicule.modele,
              ["loyer_ht"]: vehicule.modele,
              ["loyer_ttc"]: vehicule.modele,
              ["modele"]: vehicule.modele,
              ["nb_km"]: vehicule.modele,
              ["puissance_dyn"]: vehicule.modele,
              ["puissance_fiscale"]: vehicule.modele,
              ["societe_id"]: this.descriptionById("societes", vehicule.societe_id),
              ["societe_utilisatrice_id"]: this.descriptionById("societes", vehicule.societe_utilisatrice_id),
              ["tarif_assurance"]: vehicule.modele,
              ["Collaborateur"]:
                collab.collaborateur.collaborateur_nom + " " + collab.collaborateur.collaborateur_prenom,
              ["Société du collaborateur"]: lastContratForCollab(collab.collaborateur).activeVersion.contrat_siret
                .societe,
              ["Affecté le"]: this.$moment(collab.date_affectation).format("DD/MM/YYYY"),
              ["Restitution le"]: this.$moment(collab.date_restitution).format("DD/MM/YYYY"),
              ["type affectation"]: this.descriptionById("categoriesAffectation", collab.categorie_id),
            });
        });
      });
      return reponse;
    },
  },
};
</script>
<style lang="css"></style>
